import React from "react"
import tw, { styled } from "twin.macro"

const Container = styled.div`
  ${tw`flex flex-wrap hidden lg:flex mt-20`}
`

const TagButton = styled.button`
  ${tw`text-default font-content italic text-xl mr-5 font-semibold pb-2 box-border border-solid border-b-2 border-white `}

  &:focus {
    ${tw`outline-none`}
  }

  ${({ active }) => active && tw`border-black`}
`
const TagList = ({ tags, selected, onClick }) => {
  const tagList = tags.concat(["Show all"])

  return (
    <Container>
      {tagList.map(tag => (
        <TagButton
          key={tag}
          active={selected === tag}
          onClick={() => onClick(tag)}
        >
          {tag}
        </TagButton>
      ))}
    </Container>
  )
}
export default TagList
