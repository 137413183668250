import React, { useState } from "react"
import tw, { styled, css } from "twin.macro"
import { get } from "lodash"
import { Link, navigate } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const Container = styled.div`
  ${tw`w-64 h-64 xl:w-66 xl:h-66 md:w-65 md:h-65 xl:mr-4 md:mr-2 mb-6 cursor-pointer`}

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-size: cover;
    `}

    ${({ color }) =>
      color &&
      css`
        background-color: ${color};
      `}
`

const Title = styled.p`
  ${tw`text-base md:text-card`}
`
const TitleCard = styled.div`
  ${tw`flex flex-col justify-between bg-dark-green h-full text-white p-6 pt-5 font-header`}
`

const ReadMore = styled.span`
  ${tw`border-solid border-b-2 border-white text-base md:text-card`}
`

const Icon = styled(FontAwesomeIcon)`
  ${tw`ml-2`}
`

const GridItem = ({ work, filters }) => {
  const [isHovering, handleMouseHover] = useState(false)

  const bgImage = get(work, "data.grid_image.fluid.src", null)

  const bgColor = get(work, "data.grid_color", null)

  return (
    <Container
      background={bgImage}
      color={!bgImage ? bgColor : null}
      onMouseEnter={() => handleMouseHover(true)}
      onMouseLeave={() => handleMouseHover(false)}
      onFocus={() => handleMouseHover(true)}
      onBlur={() => handleMouseHover(false)}
      tabIndex={0}
    >
      {isHovering && (
        <TitleCard
          onClick={() => navigate(`/${work.uid}`, { state: { filters } })}
        >
          <Title>{work.data.title.text}</Title>
          <Link to={`/${work.uid}`} state={{ filters }}>
            <ReadMore>
              Read more <Icon icon={faArrowRight} />
            </ReadMore>
          </Link>
        </TitleCard>
      )}
    </Container>
  )
}

export default GridItem
