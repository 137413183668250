import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash/get"
import chunk from "lodash/chunk"
import tw, { styled } from "twin.macro"

import BadgesMarquee from "./BadgesMarquee"
import BadgeRow from "./BadgeRow"

const Container = styled.section`
  ${tw`flex flex-col bg-dark-green py-6 text-center text-white`}
`

const Header = styled.h3`
  ${tw`flex-1 font-header font-semibold text-subtitle md:text-5xl py-5 md:py-6`}
`

const MarqueeContainer = styled.div`
  ${tw`lg:hidden`}
`

const Skills = () => {
  const {
    allPrismicSkills: { edges },
  } = useStaticQuery(graphql`
    {
      allPrismicSkills {
        edges {
          node {
            data {
              body {
                ... on PrismicSkillsBodySkill {
                  id
                  primary {
                    badge {
                      fluid {
                        src
                        srcSet
                      }
                      alt
                    }
                  }
                }
              }
              skills {
                text
              }
            }
          }
        }
      }
    }
  `)

  const badges = get(edges, "[0].node.data.body", [])
  const half = Math.ceil(badges.length / 2)
  const heading = get(edges, "[0].node.data.skills.text", "")

  const [firstLine, secondLine] = chunk(badges, half)

  return (
    <Container>
      <BadgeRow badges={firstLine} />
      <MarqueeContainer>
        <BadgesMarquee badges={firstLine} direction="rtl" />
      </MarqueeContainer>
      <Header>{heading}</Header>
      <BadgeRow badges={secondLine} />

      <MarqueeContainer>
        <BadgesMarquee badges={secondLine} direction="ltf" />
      </MarqueeContainer>
    </Container>
  )
}

export default Skills
