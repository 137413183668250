const size = {
  smallPhone: "320px",
  phone: "600px",
  tablet: "860px",
  tabletUp: "861px",
  tabletLarge: "1024px",
  tabletLargeUp: "1025px",
  desktop: "1320px",
  desktopL: "1321px",
}

export default {
  colors: {
    black: "#1D1D1B",
    white: "#FFFFFF",
    "light-grey": "#E0E0E0",
    "light-green": "#187777",
    purple: "#6480DA",
  },
  spacing: {
    8: "0.5rem",
    10: "0.625rem",
    12: "0.75rem",
    16: "1rem",
    20: "1.25rem",
    24: "1.5rem",
    32: "2rem",
    40: "2.5rem",
    48: "3rem",
    56: "3.5rem",
    64: "4rem",
    72: "4.5rem",
    80: "5rem",
    88: "5.5rem",
    96: "6rem",
    104: "6.5rem",
    112: "7rem",
    120: "7.5rem",
  },
  devices: {
    "for-small-phone": `(max-width: ${size.smallPhone})`,
    "for-phone-only": `(max-width: ${size.phone})`,
    "for-phone-up": `(min-width: ${size.phone})`,
    "for-tablet-down": `(max-width: ${size.tablet})`,
    "for-tablet-up": `(min-width: ${size.tabletUp})`,
    "for-tablet-large-down": `(max-width: ${size.tabletLarge})`,
    "for-tablet-large-up": `(min-width: ${size.tabletLargeUp})`,
    "for-desktop-down": `(max-width: ${size.desktop})`,
    "for-large-desktop-up": `(min-width: ${size.desktopL})`,
  },
}
