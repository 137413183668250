import React from "react"
import tw, { styled } from "twin.macro"
import { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faArrowDown } from "@fortawesome/free-solid-svg-icons"

const StyledButton = styled.button`
  ${tw`bg-light-grey py-2 px-4 md:py-5 md:px-8`}

  &:focus {
    ${tw`outline-none`}
  }

  ${({ sent }) =>
    sent &&
    css`
      ${tw`bg-light-green text-white`}
    `}

  ${({ hero }) =>
    hero &&
    css`
      ${tw`inline-flex items-center cursor-pointer px-4 py-6 md:p-6 bg-light-grey`}
    `}
`

const Text = styled.span`
  ${tw`font-header font-semibold text-base md:text-2xl text-default leading-normal text-left`}

  ${({ sent }) =>
    sent &&
    css`
      ${tw`text-white`}
    `}
`

const Icon = styled(FontAwesomeIcon)`
  ${tw`ml-3 md:ml-5 text-xl`}
`

const IconContainer = styled.div`
  ${tw`flex items-center ml-2`}
`
const Arrow = styled(FontAwesomeIcon)`
  ${tw`text-lg md:text-xl`}
`

const Button = ({
  type = "button",
  text,
  sent = false,
  onClick,
  hero = false,
}) => (
  <StyledButton
    type={type}
    sent={sent}
    className={!sent && "hvr-sweep-to-right-grey"}
    onClick={onClick}
    hero={hero}
  >
    <Text sent={sent}>{text}</Text>
    {sent && <Icon icon={faCheck} />}
    {hero && (
      <IconContainer>
        <Arrow icon={faArrowDown} />
      </IconContainer>
    )}
  </StyledButton>
)

export default Button
