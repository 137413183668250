import React from "react"
import tw, { styled } from "twin.macro"

import Badge from "./Badge"

const Container = styled.div`
  ${tw`flex justify-center hidden lg:flex`}
`

const BadgeRow = ({ badges }) => (
  <Container>
    {badges.map(badge => (
      <Badge key={badge.id} badge={badge.primary.badge} />
    ))}
  </Container>
)

export default BadgeRow
