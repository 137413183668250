import React, { useState, Fragment, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { get, orderBy } from "lodash"
import tw, { styled } from "twin.macro"

import underline from "../../static/assets/purple-line.svg"
import longline from "../../static/assets/long-line.svg"

import CustomDropdown from "./Dropdown"
import TagList from "./TagList"
import GridItem from "./GridItem"
import Button from "./Button"

import useWindowSize from "../utils/useWindowSize"
import { getShowCount } from "../utils/getShowCount"

const Container = styled.section`
  ${tw`py-8 px-6 md:py-26 md:px-22 md:pb-16 xl:px-44 text-default`}
`

const TitleContainer = styled.div`
  ${tw`relative`}
`

const Title = styled.h3`
  ${tw`font-header mb-10 text-2xl md:text-title leading-normal relative font-medium`}
`

const Content = styled.div`
  ${tw`flex font-content text-base md:text-2xl font-normal leading-tight font-medium`}
`

// const Instructions = styled.p`
//   ${tw`mt-3 font-content text-base md:text-2xl font-normal leading-tight font-medium flex lg:hidden`}
// `

const Underline = styled.img`
  width: 320px;
  position: absolute;
  bottom: -7px;

  @media (max-width: 767px) {
    width: 185px;
  }
`

const SelectContainer = styled.div`
  ${tw`lg:hidden w-2/4 mt-12`}
`

const WorkContainer = styled.div`
  ${tw`flex md:flex-wrap px-6 lg:px-15 flex-wrap justify-center`}
`

const ButtonContainer = styled.div`
  ${tw`justify-center mt-6 flex`}
`

const LongLine = styled.div`
  ${tw`flex justify-center mt-6 md:mt-16`}
`

const LineImage = styled.img`
  ${tw`w-8/12 md:w-9/12`}

  height: 5px;
`

const Projects = ({ filters }) => {
  const size = useWindowSize()

  const [filter, setFilter] = useState("Show all")
  const [results, setResults] = useState([])
  const [showCount, setCount] = useState()

  useEffect(() => {
    setCount(getShowCount(size.width))
  }, [size])

  useEffect(() => {
    if (filters) {
      setCount(filters.count)
      setFilter(filters.filter)
    }
  }, [filters])

  const data = useStaticQuery(graphql`
    {
      allPrismicWork(sort: { fields: data___date, order: DESC }) {
        nodes {
          tags
          data {
            description {
              html
            }
            links {
              link {
                url
              }
              text {
                text
              }
            }
            title {
              text
            }
            grid_color
            grid_image {
              fluid {
                src
              }
            }
          }
          uid
        }
      }
      prismicWorkTitle {
        data {
          title {
            text
          }
          description {
            html
          }
          mobile_instructions {
            text
          }
        }
      }
    }
  `)

  const work = get(data, "allPrismicWork.nodes", [])

  useEffect(() => {
    if (filter === "Show all") {
      setResults(work)
    } else {
      const filtered = work.filter(w => w.tags.includes(filter))

      setResults(filtered)
    }
  }, [filter, work])

  const tagsList = work.reduce((tags, work) => {
    work.tags.map(tag => {
      if (!tags.includes(tag)) {
        tags.push(tag)
      }

      return null
    })

    return tags
  }, [])

  const tags = orderBy(tagsList, [tag => tag.toLowerCase()], ["asc"])

  const options = tags.map(tag => ({
    value: tag,
  }))

  const { title, description } = get(data, "prismicWorkTitle.data")

  return (
    <Fragment>
      <Container id="work">
        <TitleContainer>
          <Title>{title.text}</Title>
          <Underline src={underline} />
        </TitleContainer>

        <Content dangerouslySetInnerHTML={{ __html: description.html }} />

        <SelectContainer>
          <CustomDropdown
            options={[{ value: "Show all" }, ...options]}
            value={filter}
            onChange={setFilter}
          />
        </SelectContainer>

        <TagList tags={tags} selected={filter} onClick={setFilter} />
      </Container>

      <WorkContainer>
        {results.slice(0, showCount).map(work => (
          <GridItem
            key={work.uid}
            work={work}
            filters={{ filter, count: showCount }}
          />
        ))}
      </WorkContainer>

      {showCount <= results.length && (
        <ButtonContainer>
          <Button
            text="Load more"
            onClick={() => setCount(showCount + getShowCount(size.width))}
          />
        </ButtonContainer>
      )}

      <LongLine>
        <LineImage src={longline} />
      </LongLine>
    </Fragment>
  )
}

export default Projects
