import React from "react"
import Dropdown from "react-dropdown-aria"

const style = {
  Arrow: base => ({
    ...base,
    color: "#1D1D1B",
    svg: {
      strokeWidth: 3,
    },
  }),
  DropdownWrapper: base => ({
    ...base,
    backgroundColor: "#E0E0E0",
    color: "#1D1D1B",
    border: "none",
    borderRadius: 0,
    fontFamily: "basic-sans, sans-serif",

    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
  }),
  OptionContainer: base => ({
    ...base,
    border: "none",
    borderRadius: 0,

    color: "#1D1D1B",
  }),
  OptionItem: (base, state, { selected }) => ({
    ...base,
    backgroundColor: "#E0E0E0",
    color: selected && "#1D1D1B",

    "&:hover": { backgroundColor: "#E0E0E0" },
  }),
}

const CustomDropdown = ({ options, value, onChange }) => (
  <Dropdown
    placeholder="Show all"
    style={style}
    value={value}
    options={options}
    onChange={({ value }) => onChange(value)}
  />
)

export default CustomDropdown
