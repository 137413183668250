import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import {
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { css } from "styled-components"

const StyledLink = styled.a`
  ${tw`inline-flex items-center cursor-pointer px-4 py-6 md:p-6 bg-light-grey`}

  ${({ social }) =>
    social &&
    css`
      ${tw`py-2 px-4 md:py-5 md:px-6 mb-8 mr-5`}

      @media (max-width: 320px) {
        ${tw`mr-0`}
      }
    `}
`

const IconContainer = styled.div`
  ${tw`flex items-center ml-2`}
`

const Text = styled.span`
  ${tw`no-underline text-base md:text-2xl font-semibold text-default font-header leading-normal`}
  line-height: 1.45;
`

const SocialIcon = styled(FontAwesomeIcon)`
  ${tw`mr-2 md:mr-3 h-8 w-8 md:text-2xl`}

  width: 2rem;
`

const getIcon = text => {
  const socialMedia = text.toLowerCase()
  let icon

  if (socialMedia === "instagram") {
    icon = faInstagram
  }
  if (socialMedia === "twitter") {
    icon = faTwitter
  }
  if (socialMedia === "youtube") {
    icon = faYoutube
  }
  if(socialMedia === "github"){
    icon = faGithub
  }
  if(socialMedia === "linkedin"){
    icon = faLinkedin
  }

  return icon
}

const Link = ({ url, text, icon, social }) => (
  <StyledLink
    className="hvr-sweep-to-right-grey"
    social={social}
    href={url}
    target={social ? "_blank" : "_self"}
  >
    {social && <SocialIcon icon={getIcon(text)} />}
    <div>
      <Text>{text}</Text>
    </div>
    <IconContainer>
      {icon && <FontAwesomeIcon icon={faArrowDown} />}
    </IconContainer>
  </StyledLink>
)

export default Link
