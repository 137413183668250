import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "emotion-theming"
import { get } from "lodash"
import { config } from "@fortawesome/fontawesome-svg-core"

import "../styles/reset.css"
import "../styles/hover.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import theme from "../styles/theme"

import favicon from "../images/favicon.png"

import Hero from "../components/Hero"
import About from "../components/About"
import Skills from "../components/Skills"
import Projects from "../components/Projects"
import Contact from "../components/Contact"
import Footer from "../components/Footer"

config.autoAddCss = false

export default function Home({ location }) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title="Hjpaxton portfolio"
        meta={[{ name: "description", content: "Let’s design this thing" }]}
        charSet="utf-8"
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Hero />
      <About />
      <Skills />
      <Projects filters={get(location, "state.filters", null)} />
      <Contact />
      <Footer />
    </ThemeProvider>
  )
}
