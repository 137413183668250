import React from "react"
import Marquee from "react-marquee-slider"
import Badge from "./Badge"

const BadgesMarquee = ({ badges, direction }) => (
  <Marquee velocity={10} direction={direction}>
    {badges.map(badge => (
      <Badge key={badge.id} badge={badge.primary.badge} />
    ))}
  </Marquee>
)

export default BadgesMarquee
