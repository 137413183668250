import React, { useState } from "react"
import emailjs from "emailjs-com"
import Link from "./Link"

import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import Button from "./Button"

import wave from "../../static/assets/wave.svg"
import enquiries from "../../static/assets/enquiries.svg"
import enquiriesMobile from "../../static/assets/enquiries-flipped.svg"

const Container = styled.section`
  ${tw`py-10 px-6 pb-27 pb-27 md:pb-34 md:py-26 md:px-22 xl:px-44 relative overflow-hidden`}
`

const Title = styled.h3`
  ${tw`font-header font-semibold text-default text-lg md:text-subtitle mb-6 md:mb-15 leading-normal`}
`

const SocialContainer = styled.div`
  ${tw`flex flex-wrap justify-start xl:justify-between xl:w-4/6 md:mb-20`}

  @media (max-width: 320px) {
    ${tw`justify-between`}
  }
`

const Form = styled.form`
  ${tw`relative`}
`

const Label = styled.label`
  ${tw`font-header font-semibold text-base md:text-2xl text-default block leading-normal mb-1 md:mb-4`}
`

const InputBox = styled.input`
  ${tw`border-solid border border-black p-2 md:py-5 md:px-4 mb-2.5 md:mb-8 w-9/12 md:w-3/5 font-content text-base md:text-2xl text-default placeholder-placeholder`}
`

const Message = styled.textarea`
  ${tw`border-solid border border-black p-2 resize-none font-content text-base text-default md:text-2xl md:py-5 md:px-4 mb-2.5 md:mb-8 w-full md:w-9/12 placeholder-placeholder`}
`

const Wave = styled.img`
  ${tw`hidden md:flex`}

  position: absolute;
  height: 300px;
  width: 250px;
  right: -85px;
  bottom: 0;

  @media (min-width: 1024px) {
    right: -30px;
    bottom: -10px;
  }
`

const SpeechBubble = styled.img`
  ${tw`hidden md:flex`}

  position: absolute;
  height: 100px;
  right: 0;
  bottom: -50px;

  @media (min-width: 768px) {
    top: 100px;
  }

  @media (min-width: 1024px) {
    height: 175px;
  }
`

const MobileSpeechBubble = styled.img`
  position: absolute;
  height: 100px;
  right: 0;
  bottom: -50px;

  ${tw`flex md:hidden`}
`

const ButtonContainer = styled.div`
  ${tw`flex mt-4 md:mt-5`}
`

const initialValues = {
  email: "",
  name: "",
  message: "",
}

const Contact = () => {
  const [values, setValues] = useState(initialValues)

  const [sent, sendForm] = useState(false)

  const handleChange = event => {
    const { target } = event
    const { id, value } = target

    setValues({ ...values, [id]: value })
  }

  const sendEmail = async e => {
    e.preventDefault()

    const template_params = {
      userEmail: values.email,
      userName: values.name,
      userMessage: values.message,
    }

    try {
      await emailjs.send(
        "heatherpaxton",
        "heather_paxton",
        template_params,
        process.env.GATSBY_EMAIL_API_KEY
      )

      sendForm(true)
    } catch (error) {
      console.log(error.text)
    }
  }

  const {
    prismicFooter: { data },
  } = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          social {
            link {
              url
            }
            socialmedia
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>You can find me on</Title>
      <SocialContainer>
        {data.social.map(social => (
          <Link
            url={social.link.url}
            text={social.socialmedia}
            social={true}
            key={social.socialmedia}
          />
        ))}
      </SocialContainer>
      <Title>Or send me a message</Title>

      <Form onSubmit={e => sendEmail(e)}>
        <Label htmlFor="email">Email</Label>
        <InputBox
          id="email"
          type="text"
          placeholder="Where do I reply to?"
          value={values.email}
          onChange={handleChange}
        />

        <Label htmlFor="name">Name</Label>
        <InputBox
          id="name"
          type="text"
          placeholder="Who do I reply to?"
          value={values.name}
          onChange={handleChange}
        />

        <Label htmlFor="message">Message</Label>
        <Message
          id="message"
          placeholder="What do you want to chat about?"
          rows="4"
          value={values.message}
          onChange={handleChange}
        />
        <ButtonContainer>
          <Button
            type="submit"
            text={sent ? "Sent" : "Send"}
            sent={sent}
          ></Button>
        </ButtonContainer>
        <SpeechBubble src={enquiries} />
        <MobileSpeechBubble src={enquiriesMobile} />
      </Form>
      <Wave src={wave} />
    </Container>
  )
}

export default Contact
