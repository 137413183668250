import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import Button from "./Button"

import headingImage from "../../static/assets/heading-overlap.svg"
import cornerCircle from "../../static/assets/corner-yellow.svg"
import speechBubble from "../../static/assets/nice-to.svg"
import wave from "../../static/assets/wave.svg"

const Container = styled.header`
  ${tw`flex flex-col py-34 px-6 md:px-22 xl:px-44 md:py-64 overflow-hidden relative`}

  &:before {
    content: "";
    background-image: url("./assets/plant.svg");
    position: absolute;
    width: 60px;
    height: 65px;
    background-repeat: no-repeat;
    top: 50px;

    @media (min-width: 1024px) {
      top: 132px;
      left: 50px;
    }

    @media (min-width: 768px) {
      left: 42px;
      height: 100px;
      width: 90px;
    }
  }

  &:after {
    content: "";
    background-image: url("./assets/hjpaxton-scribble.svg");
    position: absolute;
    height: 130px;
    width: 80px;
    background-repeat: no-repeat;
    right: 40px;
    top: 10%;

    @media (min-width: 1024px) {
      top: 80px;
      right: 75px;
    }

    @media (max-width: 1024px) {
      right: -45px;
      top: 25px;
      width: 90px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
`

const Title = styled.h1`
  ${tw`font-semibold text-subtitle md:text-hero text-default font-header leading-normal`}
`

const Subtitle = styled.h2`
  ${tw`text-lg md:text-2xl text-default font-header mt-6 mb-14 md:mt-2.5 md:mb-20 leading-normal`}
`

const HeroButton = styled.div`
  ${tw`w-3/4`}
`

const HeaderImage = styled.img`
  position: absolute;
  height: 275px;
  top: 50px;
  right: 15px;

  @media (min-width: 1024px) {
    right: 80px;
    height: 315px;
  }

  @media (max-width: 767px) {
    height: 170px;
    right: -70px;
    top: 15px;
  }
`

const CornerCircle = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 200px;
  width: 225px;

  @media (max-width: 767px) {
    height: 102px;
    width: 115px;
  }
`

const SpeechBubble = styled.img`
  position: absolute;
  width: 165px;
  height: 120px;
  bottom: 30px;
  right: 45px;

  @media (max-width: 767px) {
    width: 100px;
    height: 75px;
    right: 17px;
  }
`

const Wave = styled.img`
  position: absolute;
  width: 100px;
  height: 120px;
  right: 150px;
  bottom: 25%;

  @media (min-width: 1280px) {
    right: 300px;
  }

  @media (max-width: 767px) {
    width: 75px;
    height: 88px;
    right: -30px;
    top: 45%;
  }
`

const Hero = () => {
  const {
    prismicHero: { data },
  } = useStaticQuery(graphql`
    {
      prismicHero {
        data {
          button {
            text
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
      }
    }
  `)

  const { button, subtitle, title } = data

  return (
    <Container>
      <HeaderImage src={headingImage} />
      <Title>{title.text}</Title>
      <Subtitle>{subtitle.text}</Subtitle>
      <HeroButton>
        <Button
          onClick={() => {
            const workDiv = document.getElementById("work")
            workDiv.scrollIntoView()
          }}
          text={button.text}
          hero={true}
        ></Button>
      </HeroButton>
      <CornerCircle src={cornerCircle} />
      <SpeechBubble src={speechBubble} />
      <Wave src={wave} />
    </Container>
  )
}

export default Hero
