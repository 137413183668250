import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import underline from "../../static/assets/white-line.svg"

const AboutContainer = styled.section`
  ${tw`py-10 px-6 md:py-26 md:px-22 xl:px-44 bg-light-green text-white font-content`}
`

const Title = styled.h2`
  ${tw`font-header mb-10 text-2xl md:text-title leading-normal relative font-medium`}
`

const TitleContainer = styled.div`
  ${tw`relative`}
`

const Content = styled.div`
  ${tw`flex font-content text-base md:text-2xl font-normal leading-tight flex-col md:flex-row font-medium`}

  p {
    ${tw`flex-1`}

    &:first-of-type {
      ${tw`lg:pr-24 sm:pr-20`}
    }
  }
`

const Underline = styled.img`
  width: 220px;
  position: absolute;
  bottom: -7px;

  @media (max-width: 767px) {
    width: 140px;
  }
`

const About = () => {
  const {
    prismicAbout: { data },
  } = useStaticQuery(graphql`
    {
      prismicAbout {
        data {
          about {
            html
          }
          title {
            text
          }
        }
      }
    }
  `)

  const { title, about } = data

  return (
    <AboutContainer>
      <TitleContainer>
        <Title>{title.text}</Title>
        <Underline src={underline} />
      </TitleContainer>
      <Content dangerouslySetInnerHTML={{ __html: about.html }}></Content>
    </AboutContainer>
  )
}

export default About
