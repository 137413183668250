import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import happy from "../../static/assets/happy.svg"

const FooterContainer = styled.footer`
  ${tw`flex flex-col items-center py-10 px-6 md:py-26 md:px-22 xl:px-44 bg-purple text-center text-white`}
`

const Text = styled.p`
  ${tw`font-header font-semibold text-2xl leading-relaxed mt-3 mb-8 md:text-3xl`}
`

const Copyright = styled.div`
  ${tw`font-content text-base leading-tight md:text-2xl`}
`

const Image = styled.img`
  width: 200px;
  height: 200px;

  @media (max-width: 767px) {
    width: 180px;
    height: 180px;
  }
`
const Footer = () => {
  const {
    prismicFooter: { data },
  } = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          copyright {
            html
          }
          text {
            text
          }
        }
      }
    }
  `)

  return (
    <FooterContainer>
      <Image src={happy} />
      <Text>{data.text.text}</Text>
      <Copyright dangerouslySetInnerHTML={{ __html: data.copyright.html }} />
    </FooterContainer>
  )
}

export default Footer
