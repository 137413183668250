import React from "react"
import tw, { styled } from "twin.macro"

const Container = styled.div`
  ${tw`m-5 md:m-8`}

  max-height: 145px;
  max-width: 145px;

  @media (max-width: 767px) {
    height: 80px;
    width: 80px;
  }
`

const Image = styled.img`
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
`

const Badge = ({ badge }) => (
  <Container>
    <Image
      key={badge.alt}
      src={badge.fluid.src}
      srcSet={badge.fluid.srcSet}
      alt={badge.alt}
    />
  </Container>
)

export default Badge
